const IconStar = () => (
    <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.507 0.173004L4.52866 5.25961H0.00700378L3.75134 8.01528L2.26783 13.173L6.507 9.78126L10.7462 13.173L9.26167 8.01528L13.007 5.25961H8.48535L6.507 0.173004Z"
            fill="#FEEE3F"
        />
    </svg>
);

export default IconStar;
